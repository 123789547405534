import {
  container,
  Current,
  eventCenter, Events,
  getCurrentInstance,
  nextTick,
  options,
  SERVICE_IDENTIFIER
} from '@tarojs/runtime';
import taro from '@tarojs/api';
import "@tarojs/components/dist/taro-components/taro-components.css";
import '@antmjs/vantui/lib/index.css';

const hooks = container.get(SERVICE_IDENTIFIER.Hooks)
if (typeof hooks.initNativeApi === 'function') {
  hooks.initNativeApi(taro)
}

//https://github.com/NervJS/taro/commit/d7f513f5f824aefc78cd8f9e790cc47b2cc4b0f1
import * as hooksApi from '@tarojs/plugin-framework-react/dist/runtime';
import React, {Component} from 'react';
import ReactDom from 'react-dom';

//https://github.com/AntmJS/vantui/blob/9fe15623569e626c8cbc29eddc5e3006920747bc/packages/vantui/src/init/index.tsx
//hooksapi 需要createReactApp
class App extends Component {
  render() {
     return <></>
   }
}
hooksApi.createReactApp(App, React, ReactDom, {});



import * as taroh5 from '@tarojs/taro-h5';

let apis = {
  navigateTo:function(...args){
    return wx.navigateTo.apply(wx,args);
  },
  navigateBack:function(...args){
    return wx.navigateBack.apply(wx,args);
  },
  redirectTo:function(...args){
    return wx.redirectTo.apply(wx,args);
  },
  switchTab:function(...args){
    return wx.switchTab.apply(wx,args);
  },
  switchTo:function(...args){
    return wx.switchTo.apply(wx,args);
  }
}
let mxTaro =  {...taro,...taroh5,...hooksApi,...apis,Current};



export * from '@tarojs/plugin-framework-react/dist/runtime';
export * from '@tarojs/taro-h5';
export * from '@tarojs/api';
export default mxTaro;

console.log("h5" + taro.Events)
console.log("h5" + taro)
export const {navigateTo,navigateBack,redirectTo,switchTab,switchTo} = apis;
